import React from "react"
import { Link } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"

import './terms.scss'

const Terms = () => (
  <Layout>
    <SEO title="利用規約" />
    <div className='terms'>
      <section>

        <h1>利用規約</h1>
        <p>
          おさいふ(以下本サービス)において、以下のように利用規約を定めています。
          当サービスを利用するには以下について同意する必要があります。
        </p>

        <h2>第1条 適用</h2>
        <ol>
          <li>本規約は、本サービスの提供条件および本サービスの利用に関する当社と登録ユーザとの間の権利義務関係を定めることを目的とし、登録ユーザと当社との間の本サービスの利用にかかわる一切の関係に適用されます。</li>
          <li>当社が当社ウェブサイト(https://zatsuzen.com)上で掲載する本サービス利用に関するルールは、本規約の一部を構成するものとします。</li>
          <li>本規約の内容と、前項のルールその他の本規約外における本サービスの説明等とが異なる場合は、本規約の規定が優先して適用されるものとします。</li>
        </ol>

        <h2>第2条 定義</h2>
        <ol>
          <li>「サービス利用契約」とは、本規約を契約条件として当社と登録ユーザーの間で締結される、本サービスの利用契約を意味します。</li>
          <li>「知的財産権」とは、著作権、特許権、実用新案権、意匠権、商標権その他の知的財産権(それらの権利を取得し、またはそれらの権利につき登録等を出願する権利を含みます)を意味します。</li>
          <li>「当社」とは、サービス作成者である<a href='https://twitter.com/akanewz' target='_blank' rel='noopener noreferrer'>あかね</a>を意味します。</li>
          <li>「当社ウェブサイト」とは、そのドメインが<span className='code'>zatsuzen.com</span>である、当社が運営するウェブサイト（理由の如何を問わず、当社のウェブサイトのドメインまたは内容が変更された場合は、当該変更後のウェブサイトを含みます）を意味します。</li>
          <li>「登録ユーザ」とは、第3条 利用登録に基づいて本サービスの利用者としての登録がなされた個人を意味します。</li>
          <li>「本サービス」とは、当社が提供するおさいふという名称のサービス(理由の如何を問わずサービスの名称または内容が変更された場合は、当該変更後のサービスを含みます)を意味します。</li>
        </ol>

        <h2>第3条 サービスの内容</h2>
        <p>本サービスは、登録ユーザが送信するデータをデータベースに記録し一覧表示するサービスです。また、他の登録ユーザ1人に限り共有設定を行うことでデータベースを共有することができます。</p>

        <h2>第3条 利用登録</h2>
        <ol>
          <li>本サービスの利用を希望する者（以下「登録希望者」といいます。）は、本規約を遵守することに同意し、かつ当社の定める一定の情報（以下「登録事項」といいます。）を当社の定める方法で当社に提供することにより、当社に対し、本サービスの利用の登録を申請することができます。</li>
          <li>当社は、当社の基準に従って、第１項に基づいて登録申請を行った登録希望者（以下「登録申請者」といいます。）の登録の可否を判断し、当社が登録を認める場合にはその旨を登録申請者に通知します。登録申請者の登録ユーザーとしての登録は、当社が本項の通知を行ったことをもって完了したものとします。</li>
          <li>前項に定める登録の完了時に、サービス利用契約が登録ユーザーと当社の間に成立し、登録ユーザーは本サービスを本規約に従い利用することができるようになります。</li>
          <li>当社は、登録申請者が、以下の各号のいずれかの事由に該当する場合は、登録及び再登録を拒否することがあり、またその理由について一切開示義務を負いません。
            <ol>
              <li>当社に提供した登録事項の全部または一部につき虚偽、誤記または記載漏れがあった場合</li>
              <li>反社会的勢力等(暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ)である、または資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っていると当社が判断した場合</li>
              <li>過去当社との契約に違反した者またはその関係者であると当社が判断した場合</li>
              <li>第10条に定める措置を受けたことがある場合</li>
              <li>その他、登録を適当でないと当社が判断した場合</li>
            </ol>
          </li>
        </ol>

        <h2>第4条 利用事項の変更</h2>
        <p>登録ユーザーは、登録事項に変更があった場合、当社の定める方法により当該変更事項を遅滞なく当社に通知するものとします。</p>

        <h2>第5条 ユーザIDおよびパスワードの管理</h2>
        <ol>
          <li>登録ユーザは、自己の責任において、本サービスに関するパスワード及びユーザIDを適切に管理及び保管するものとし、これを第三者に利用させ、または貸与、譲渡、名義変更、売買等をしてはならないものとします。</li>
          <li>パスワードまたはユーザIDの管理不十分、使用上の過誤、第三者の使用等によって生じた損害に関する責任は登録ユーザーが負うものとします。</li>
        </ol>

        <h2>第6条 利用料金</h2>
        <p>登録ユーザは、本サービスの基本的な機能を無償でご利用いただけます。</p>

        <h2>第7条 禁止事項</h2>
        <p>登録ユーザは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為または該当すると当社が判断する行為をしてはなりません。</p>
        <ol>
          <li>法令に違反する行為または犯罪行為に関連する行為</li>
          <li>当社、本サービスの他の利用者またはその他の第三者に対する詐欺または脅迫行為</li>
          <li>公序良俗に反する行為</li>
          <li>本サービスのネットワークまたはシステム等に過度な負荷をかける行為</li>
          <li>本サービスの運営を妨害するおそれのある行為</li>
          <li>当社のネットワークまたはシステム等への不正アクセス</li>
          <li>第三者に成りすます行為</li>
          <li>本サービスの他の利用者のIDまたはパスワードを利用する行為</li>
          <li>本サービスの他の利用者の情報の収集</li>
          <li>反社会的勢力等への利益供与</li>
          <li>その他、当社が不適切と判断する行為</li>
        </ol>

        <h2>第8条 本サービスの停止等</h2>
        <p>当社は、以下のいずれかに該当する場合には、登録ユーザに事前に通知することなく、本サービスの全部または一部の提供を停止または中断することができるものとします。</p>
        <ol>
          <li>本サービスに係るコンピューター・システムの点検または保守作業を緊急に行う場合</li>
          <li>コンピューター、通信回線等の障害、誤操作、過度なアクセスの集中、不正アクセス、ハッキング等により本サービスの運営ができなくなった場合</li>
          <li>地震、落雷、火災、風水害、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合</li>
          <li>その他、当社が停止または中断を必要と判断した場合</li>
        </ol>

        <h2>第9条 権利帰属</h2>
        <p>当社ウェブサイト及び本サービスに関する知的財産権は全て当社または当社にライセンスを許諾している者に帰属しており、本規約に基づく本サービスの利用許諾は、当社ウェブサイトまたは本サービスに関する当社または当社にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。</p>

        <h2>第10条 利用抹消等</h2>
        <ol>
          <li>当社は、登録ユーザが、以下の各号のいずれかの事由に該当する場合は、事前に通知または催告することなく、投稿データを削除もしくは非表示にし、当該登録ユーザについて本サービスの利用を一時的に停止、または登録ユーザーとしての登録を抹消することができます。</li>
          <ol>
            <li>本規約のいずれかの条項に違反した場合</li>
            <li>6ヶ月以上本サービスの利用がない場合</li>
            <li>その他、当社が本サービスの利用または登録ユーザーとしての登録の継続を適当でないと判断した場合</li>
          </ol>
        </ol>

        <h2>第11条 退会</h2>
        <ol>
          <li>登録ユーザーは、当社所定の手続の完了により、本サービスから退会し、自己の登録ユーザーとしての登録を抹消することができます。</li>
          <li>退会後の利用者情報の取扱いについては、第15条の規定に従うものとします。</li>
        </ol>

        <h2>第12条 本サービスの内容の変更、終了</h2>
        <ol>
          <li>当社は、当社の都合により、本サービスの内容を変更し、または提供を終了することができます。</li>
          <li>当社が本サービスの提供を終了する場合、当社はウェブサイト内で事前に通知するものとします。</li>
        </ol>

        <h2>第13条 保証の否認および免責</h2>
        <ol>
          <li>当社は、本サービスが登録ユーザーの特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、登録ユーザーによる本サービスの利用が登録ユーザーに適用のある法令または業界団体の内部規則等に適合すること、継続的に利用できること、及び不具合が生じないことについて、明示又は黙示を問わず何ら保証するものではありません。</li>
          <li>本サービスまたは当社ウェブサイトに関連して登録ユーザと他の登録ユーザまたは第三者との間において生じた取引、連絡、紛争等については、登録ユーザーが自己の責任によって解決するものとします。</li>
        </ol>

        <h2>第14条 秘密保持</h2>
        <p>登録ユーザは、本サービスに関連して当社が登録ユーザに対して秘密に取扱うことを求めて開示した非公知の情報について、当社の事前の書面による承諾がある場合を除き、秘密に取扱うものとします。</p>

        <h2>第15条 利用者情報の取り扱い</h2>
        <ol>
          <li>当社による登録ユーザの利用者情報の取扱いについては、別途当社<Link to='/policy'>プライバシーポリシー</Link>の定めによるものとし、登録ユーザはこのプライバシーポリシーに従って当社が登録ユーザの利用者情報を取扱うことについて同意するものとします。</li>
        </ol>

        <h2>第16条 本規約等の変更</h2>
        <p>当社は、当社が必要と認めた場合は、本規約を変更できるものとします。法令上登録ユーザの同意が必要となるような内容の変更の場合は、当社所定の方法で登録ユーザの同意を得るものとします。</p>

        <h2>第17条 分離可能性</h2>
        <p>本規約のいずれかの条項またはその一部が、消費者契約法その他の法令等により無効または執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。</p>

        <h2>第18条 準拠法および管轄裁判所</h2>
        <ol>
          <li>本規約及びサービス利用契約の準拠法は日本法とします。</li>
          <li>本規約またはサービス利用契約に起因し、または関連する一切の紛争については、新潟地方裁判所を第一審の専属的合意管轄裁判所とします。</li>
        </ol>

        <div className='update'>
          <ol>
            <li>2019年11月18日制定</li>
          </ol>
        </div>

      </section>
    </div>
  </Layout>
)

export default Terms
